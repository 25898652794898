// ** Initial State
const initialState = {
  reporting_tos: [],
  allData: [],
  data: [],
  total: 1, 
  params: {},
  selectedEmployee: null,
  attendanceLogs: [],
  attendanceMonth: new Date().getMonth() + 1,
  attendanceYear: new Date().getFullYear(),
  leaveLogs: [],
  leaveYear: new Date().getFullYear(),
  leavesTakenThisMonth: 0,
  totalLeavesTaken: 0,
  remainingLeaves: 0,
  add: null,
  update: null,
  updateBank: null,
  updatePreviousEmployment: null,
  updatePayroll: null,
}

const Employees = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_REPORTING_TOS':
      return { ...state, reporting_tos: action.data }
    case 'GET_ALL_EMPLOYEES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_EMPLOYEES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_EMPLOYEE':
      return { ...state, selectedEmployee: action.selectedEmployee }
    case 'ADD_EMPLOYEE':
      return { ...state, add: action.data }
    case 'UPDATE_EMPLOYEE':
      return { ...state, update: action.data }
    case 'UPDATE_EMPLOYEE_BANK':
      return { ...state, updateBank: action.data }
    case 'UPDATE_PREVIOUS_EMPLOYMENT':
      return { ...state, updatePreviousEmployment: action.data }
    case 'UPDATE_EMPLOYEE_PAYROLL':
      return { ...state, updatePayroll: action.data }
    case 'DELETE_EMPLOYEE':
      return { ...state }
    case 'CHECK_EMPLOYEE_EXSIST':
      return { ...state }
    case 'GET_EMPLOYEE_ATTENDANCE_LOGS':
      return { ...state, attendanceLogs: action.data }
    case 'SET_ATTENDANCE_LOG_MONTH':
      return { ...state, attendanceMonth: action.data }
    case 'SET_ATTENDANCE_LOG_YEAR':
      return { ...state, attendanceYear: action.data }
    case 'GET_EMPLOYEE_LEAVE_LOGS':
      return { ...state, 
        leaveLogs: action.data.logs,
        leavesTakenThisMonth: action.data.leave_taken_this_month,
        totalLeavesTaken: action.data.total_leaves_taken,
        remainingLeaves: action.data.remaining_leaves,
      }
    case 'SET_LEAVE_LOG_YEAR':
      return { ...state, leaveYear: action.data }
    default:
      return { ...state }
  }
}
export default Employees
