// ** Initial State
const initialState = {
  data: null
}

const myProfile = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MY_PROFILE_USER_INFO':
      return { ...state, data: action.data }
    case 'CREATE_OR_UPDATE_MY_PROFILE_USER_INFO':
      return { ...state, data: action.data }
    default:
      return { ...state }
  }
}
export default myProfile
