// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

// Custom Reducers
// Admin
import users from './UserReducer'
import countries from './CountryReducer'
import elements from './ElementReducer'
import workpassTypes from './WorkpassTypeReducer'
import workpassOccupations from './WorkpassOccupationReducer'
import religions from './ReligionReducer'
import banks from './BankReducer'
import modules from './ModuleReducer'
import moduleElements from './ModuleElementReducer'
import packages from './PackageReducer'
import packageModules from './PackageModuleReducer'
import tenants from './TenantReducer'
import globalLeaveTypes from './GlobalLeaveTypeReducer'
import cpfAges from './CpfAgeReducer'
import cpfWages from './CpfWageReducer'

// Common
import pricings from './PricingReducer'
import register from './RegisterReducer'
import otp from './OtpReducer'
import myProfile from './MyProfileReducer'

// User
import companies from './CompanyReducer'
import employees from './EmployeeReducer'
import clients from './ClientReducer'
import projects from './ProjectReducer'
import employeeLeaveSettings from './EmployeeLeaveSettingReducer'
import roles from './RoleReducer'
import employeeGroups from './EmployeeGroupReducer'
import grades from './GradeReducer'
import classes from './ClassReducer'
import functionalGroups from './FunctionalGroupReducer'
import momSurveyGroups from './MomSurveyGroupReducer'
import departments from './DepartmentReducer'
import departmentLeaveSettings from './DepartmentLeaveSettingReducer'
import designations from './DesignationReducer'
import workLocations from './WorkLocationReducer'
import shifts from './ShiftReducer'
import leaveIncrements from './LeaveIncrementReducer'
import leaveSettings from './LeaveSettingReducer'
import leaveTypes from './LeaveTypeReducer' // Not Used
import yearSettings from './YearSettingReducer'
import holidays from './HolidayReducer'
import timesheets from './TimesheetReducer'
import sdls from './SdlReducer'
import cdacs from './CdacReducer'
import ecs from './EcReducer'
import mbms from './MbmReducer'
import sindas from './SindaReducer'
import cpfs from './CpfReducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,

  // Custom
  users,
  countries,
  elements,
  workpassTypes,
  workpassOccupations,
  religions,
  banks,
  modules,
  moduleElements,
  packages,
  packageModules,
  tenants,
  globalLeaveTypes,
  cpfAges,
  cpfWages,
  
  pricings,
  register,
  otp,
  myProfile,
  
  companies,
  employees,
  clients,
  projects,
  employeeLeaveSettings,
  roles,
  employeeGroups,
  grades,
  classes,
  functionalGroups,
  momSurveyGroups,
  departments,
  departmentLeaveSettings,
  designations,
  workLocations,
  shifts,
  leaveIncrements,
  leaveSettings, // Not Used
  leaveTypes, // Not Used
  yearSettings,
  holidays,
  timesheets,
  sdls,
  cdacs,
  ecs,
  mbms,
  sindas,
  cpfs,

  todo,
  chat,
  email,
  invoice,
  calendar,
  ecommerce,
  dataTables
})

export default rootReducer
